<template>
	<div class="detailCon" :class="!isMobile ? 'pcbox' : ''">
		<van-nav-bar v-if="isMobile" :title="content.title" left-arrow @click-left="onClickLeft" />
		<div class="conBox">
			<h3 class="title">{{content.title}}</h3>
			<div class="sourceTxt">{{content.source}}</div>
			<div class="detailBox" v-html="content.details"></div>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import { getIntroductionDetail } from "@/api/index";
	import useConfigStore from '@/store/modules/config'
	
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const content = ref('')
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	onMounted(() => {
		const query = router.currentRoute.value.params
		getIntroductionDetail(query.id).then((res) => {
			content.value = res.data
		})
	})
	const onClickLeft = () => {
		history.back()
	}
</script>

<style lang="less" scoped>
	
	.detailCon{
		width: 100%;height: calc(100vh - 2.5rem);overflow-y: scroll;
		&.pcbox{
			min-height: calc(100vh - 160px);height: auto;
			.conBox{
				background: #fff;width: 600px;margin: 0 auto;
			}
		}
	}
	.conBox{
		width: 100%;padding: 0 0.75rem;box-sizing: border-box;
		.title{
			padding-top: 0.625rem;
		}
		.sourceTxt{
			font-size: 0.75rem;color: #999;margin-top: 0.5rem;
		}
		.detailBox{
			font-size: 0.8125rem;;padding: 0.75rem 0;box-sizing: border-box;
			word-wrap: break-word;line-height: 1.125rem;
			/deep/ img{
				max-width: 100%;
			}
		}
	}
</style>